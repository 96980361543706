import React, { Suspense, useMemo } from 'react'

import { PrivacyShield } from '@consent-manager/core'
import { t } from '@lingui/macro'

import Loading from '../lazy/loading'

import './widget.css'
import { useLingui } from '@lingui/react'

const Search = React.lazy(
  () => import(/* webpackChunkName: "search-widget" */ './index')
)

export default function SearchWidget() {
  const { i18n } = useLingui()

  const searchIndices = useMemo(() => {
    return [
      { name: `Pages`, title: t`Pages`, hitComp: `PageHit` },
      {
        name: `BoardingSchools`,
        title: t`Boarding Schools`,
        hitComp: `SchoolHit`,
      },
      {
        name: `SummerSchools`,
        title: t`Summer Schools`,
        hitComp: `SchoolHit`,
      },
      {
        name: `BlogPosts`,
        title: t`News`,
        hitComp: `PageHit`,
      },
    ]
  }, [i18n.locale])

  return (
    <div className="search-wrapper">
      <PrivacyShield id="algolia">
        <Suspense fallback={<Loading />}>
          <Search collapse indices={searchIndices} />
        </Suspense>
      </PrivacyShield>
    </div>
  )
}

import React, { Suspense, useContext, useEffect, useMemo } from 'react'
import propTypes from 'prop-types'
import { useLocation } from '@reach/router'
import { ConsentManagerWrapper } from '../../consent-manager'
import LazyComponent from '../lazy/lazy-component'
import Loading from '../lazy/loading'
import { Footer } from '../footer'
import Popup from './popup'

import '../../global.css'

import { setupI18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { messages as deMessages } from '../../locales/de/messages-de'
import { messages as enMessages } from '../../locales/en/messages-en'
import MdxSuiteContext from '../../contexts/mdx-suite'

const SchoolCarousel = React.lazy(
  () => import(/* webpackChunkName: "layout-carousel" */ '../school-carousel')
)

const Layout = ({ children, pageContext }) => {
  const location = useLocation()
  const MdxSuiteData = useContext(MdxSuiteContext)

  const isDocs = useMemo(
    () =>
      (location.pathname.indexOf('/docs') === 0 &&
        location.pathname.indexOf('/docs/preview') === -1) ||
      location.pathname.indexOf('/contentful') === 0,
    [location]
  )

  const i18n = useMemo(() => {
    return setupI18n({
      locale: pageContext.locale,
      locales: ['de', 'en'],
      messages: {
        de: deMessages,
        en: enMessages,
      },
    })
  }, [pageContext.locale])

  useEffect(() => {
    i18n.activate(pageContext.locale)
  }, [i18n, pageContext.locale])

  return (
    <I18nProvider i18n={i18n}>
      <ConsentManagerWrapper>
        <MdxSuiteContext.Provider
          value={{
            ...MdxSuiteData,
            pageContext,
          }}
        >
          <main className="flex flex-col min-h-screen bg-root-background antialiased">
            <Popup pageContext={pageContext} />
            <div className="flex-auto w-full">{children}</div>
            {!isDocs && (
              <>
                <LazyComponent>
                  <Suspense fallback={<Loading />}>
                    <SchoolCarousel />
                  </Suspense>
                </LazyComponent>
                <Footer />
              </>
            )}
          </main>
        </MdxSuiteContext.Provider>
      </ConsentManagerWrapper>
    </I18nProvider>
  )
}

Layout.propTypes = {
  children: propTypes.node.isRequired,
}

export default Layout

import React, { useContext } from 'react'
import propTypes from 'prop-types'
import clsx from 'clsx/lite'

import Image from '../../components/image'
import MdxSuiteContext from '../../contexts/mdx-suite'

import CTA from '../mdx/link/cta'
import Overlap from '../overlap'

import { useFormatSupportLineBreaks } from '../../hooks/formatText'

import './blog-post-teaser.css'

import { t } from '@lingui/macro'

import { MDXRenderer } from 'gatsby-plugin-mdx'
import Link from '../mdx/link/link-renderer'
import { useLingui } from '@lingui/react'

const BlogPostTeaser = ({ blogPost, ...props }) => {
  const i18n = useLingui()
  const {
    pageContext: { locale: activeLocale },
  } = useContext(MdxSuiteContext)

  const formattedTitle = useFormatSupportLineBreaks(blogPost.title)
  return (
    <div
      className={clsx(
        'flex flex-col h-full no-underline text-inherit hover:text-inherit relative',
        props.hideOnDesktop && 'hidden'
      )}
    >
      <Link
        id={blogPost.sys.pageId}
        className="absolute w-full h-full t-0 l-0 z-20"
      >
        <span />
      </Link>
      <Overlap
        image={
          blogPost.image && (
            <Image
              className="absolute! inset-0"
              imageData={blogPost.image}
              fit="cover"
            />
          )
        }
      >
        <h1
          className="my-0 text-xl leading-relaxed flex items-center"
          styles={{ minHeight: '6rem' }}
        >
          {formattedTitle}
        </h1>

        <div className="blog-post-teaser text-sm text-gray-800 md:text-gray-700 mb-4 mt-2 py-2">
          {Intl.DateTimeFormat(activeLocale).format(
            new Date(blogPost.publicationDate)
          )}{' '}
          - {t(i18n)`${blogPost.content.childMdx.timeToRead} min. to read`}
        </div>

        <div className="text-sm">
          <MDXRenderer>{blogPost.teaser.childMdx.body}</MDXRenderer>
        </div>

        <div className="mt-8">
          <CTA id={blogPost.sys.pageId}>{t(i18n)`Read more...`}</CTA>
        </div>
      </Overlap>
    </div>
  )
}

BlogPostTeaser.defaultProps = {
  hideOnDesktop: false,
}

BlogPostTeaser.propTypes = {
  blogPost: propTypes.object.isRequired,
  hideOnDesktop: propTypes.bool,
}

export default BlogPostTeaser

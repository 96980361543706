import { useStaticQuery, graphql, Link } from 'gatsby'

import React, { useMemo, useState, useCallback, useEffect } from 'react'
import propTypes from 'prop-types'
import clsx from 'clsx/lite'

import Icon from '../icon'

import LanguageSwitch from './language-switch'
import MenuPopper from './menu-popper'
import { useTrackContactEvent } from '../../hooks/tracking'
import { Popper } from '../popper'
import SearchWidget from '../search/widget'

import './navigation-desktop.css'

const TopBarLink = ({ children, className, ...props }) => (
  <a
    className={clsx('hover:text-secondary hover:no-underline', className)}
    {...props}
  >
    {children}
  </a>
)

const NavigationDesktop = ({
  logo,
  rootMenuItemId = '5yOQJ6BNP2pahfJzETReqe',
  transparent,
  headerPassed,
  isScrollingUp = false,
}) => {
  const trackContactEvent = useTrackContactEvent()
  const result = useStaticQuery(graphql`
    query StanfordAckelNavigationDesktopQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const popperOffset = useMemo(() => {
    if (transparent && !headerPassed) {
      return [0, 25]
    }
    return [0, 50]
  }, [headerPassed, transparent])

  const [referenceElementSearch, setReferenceElementSearch] = useState(null)
  const [showSearch, setShowSearch] = useState(false)
  const toggleSearch = useCallback(
    () => setShowSearch((v) => !v),
    [setShowSearch]
  )

  const [topBarHeight, setTopBarHeight] = useState(null)
  const handleTopBarHeight = useCallback((e) => {
    setTopBarHeight(e?.offsetHeight || 0)
  }, [])

  const [contentBarHeight, setContentBarHeight] = useState(null)
  const handleContentBarHeight = useCallback((e) => {
    setContentBarHeight(e?.offsetHeight || 0)
  }, [])

  // Set the CSS variable on the root element
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--navbar-small-height',
      `${contentBarHeight}px`
    )
  }, [contentBarHeight])

  return (
    <div
      className={clsx(
        'navigation-desktop',
        transparent && !headerPassed && `text-white`
      )}
    >
      <div
        className={clsx(
          'border-b border-secondary flex items-end',
          !headerPassed && transparent && `border-white`,
          headerPassed && 'header-passed',
          headerPassed && isScrollingUp && 'border-secondary'
        )}
        style={{
          fontSize: '18px',
          height: headerPassed && isScrollingUp ? `${topBarHeight}px` : null,
        }}
        ref={handleTopBarHeight}
      >
        <div className="content-wrapper flex justify-end gap-8 items-center flex-wrap">
          <TopBarLink
            href="mailto:info@stanford-ackel.com"
            onClick={() => trackContactEvent('Email')}
            className="flex items-center"
          >
            <Icon className="text-xl mr-2" icon="mail" display="block" />
            <span>info@stanford-ackel.com</span>
          </TopBarLink>
          <TopBarLink
            href="tel:+498960013850"
            onClick={() => trackContactEvent('Phone')}
            className="flex items-center"
          >
            <Icon className="text-xl mr-2" icon="phone" display="block" />
            <span>+ 49 (0)89 60 01 38 50</span>
          </TopBarLink>
          <div className="w-20 flex justify-end">
            <LanguageSwitch className="text-2xl" />
          </div>
        </div>
      </div>
      <div className="content-wrapper" ref={handleContentBarHeight}>
        <div className="flex justify-between items-center flex-wrap gap-8">
          <div className="logo-wrapper">
            <Link to="/" title={result.site.siteMetadata.title}>
              {logo}
            </Link>
          </div>
          <div className="menu-wrapper">
            <MenuPopper
              rootMenuItemId={rootMenuItemId}
              depth={1}
              offset={popperOffset}
            />
            <div
              className="w-20 flex justify-end"
              ref={setReferenceElementSearch}
            >
              <div
                className="block p-4 text-2xl cursor-pointer"
                onClick={toggleSearch}
              >
                <Icon icon="search" display="block" />
              </div>
            </div>
          </div>
          {showSearch && (
            <Popper
              referenceElement={referenceElementSearch}
              offset={popperOffset}
              options={{ placement: 'bottom-end' }}
            >
              <SearchWidget />
            </Popper>
          )}
        </div>
      </div>
    </div>
  )
}

NavigationDesktop.propTypes = {
  logo: propTypes.element.isRequired,
  rootMenuItemId: propTypes.string,
  transparent: propTypes.bool,
}

export default NavigationDesktop
